.responsivePage {
  background-color: #333333;
  min-height: -webkit-fill-available;
  height: -webkit-fill-available;

  /* CSS specific to iOS devices */ 
  @supports (-webkit-touch-callout: none) {
    height: auto;
  }

  /* CSS for other than iOS devices */ 
  @supports not (-webkit-touch-callout: none) {
    max-height: 100vh;
  }
  
  @media (min-width: 430px) {
    padding-block: 5vh;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

}

.pageWrapper {
  background-color: #333333;
  width: 100%;
  min-height: -webkit-fill-available;
  position: relative;

  @media (max-width: 430px) {
    min-height: 100vh;
  }

  @media (min-width: 430px) {
    width: 400px;
    max-height: 100vh;
    min-height: 90vh;
    box-shadow: 0 0 11px 0;
    display: flex;
    flex-direction: column;
  }

}

.navbarWrapper {
  width: 100%;
  height: var(--navbar-height);
  background: linear-gradient(310.52deg, #141214 10.16%, #383324 63.78%);
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: var(--navbar-margin);
  position: sticky;
  top: 0;
  z-index: 100;

  a {
    font-size: 18px;
    font-family: "Heebo";
    color: #8f8989;
    font-weight: 500;

    &[data-current="true"] {
      color: #333333;
      background-color: #cc9933;
      padding: 4px 9px;
      border-radius: 6px;
    }
  }
}
