.sliderContainer {
    height: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: -130px;
    padding-block: 10px;
    right: 0;
    left: 0;
}