@import './mixins';
@import './utils';

html,
body {
	direction: rtl;
  padding: 0;
  margin: 0;
  font-family: "Frank Ruhl Libre";
  background-color: var(--banana);

  font-size: 30px;

  @include media-breakpoint(sm) {
	font-size: 20px;
}
}

h1, h2, h3, h4, h5 {
	font-family: "Karantina";
}

body {
    max-width: 100vw;
    overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

:root {
	--padding-default: 15px;
	--footerHeight: 140px;
	--dark-green: #042901;
	--light-dark-green: #095502;
	--light-green: #066600;
	--banana: #ded15b;

	--shadow: 3px 3px 4px #000000;
	--inverted-shadow: -3px -3px 4px #000000;

	--player-height: 110px;
	--navbar-height: 90px;
	--navbar-margin: 15px;

	--navbar-width: 200px;

	--SPACE1: 15px;
	--SPACE2: 25px;
	--SPACE3: 50px;
	--SPACE4: 75px;
	--SPACE5: 100px;

	--play-button-width: 150px;
	
	@include media-breakpoint(sm) {
		--SPACE1: 5px;
		--SPACE2: 10px;
		--SPACE3: 15px;
		--SPACE4: 20px;
		--SPACE5: 25px;
		--footerHeight: 70px;
		--navbar-width: 70px;
		--play-button-width: 80px;
	}
}

.section-title {
	margin-bottom: 25px;
	text-align: center;
	font-size: 1rem;
}

.ltr {
	direction: ltr;
}

.rtl {
	direction: rtl;
}
h1 {
	font-size: 2.5rem;
}

h2 {
	font-size: 1.7rem;
}

img {
	max-width: 100%;
}

.carousel-root {
	position: relative;
	.carousel.carousel-slider {
		max-height: 695px;
		padding-bottom: 95px;
		.slide img {
			max-height: 600px;
			@include media-breakpoint(sm) {
				max-height: 250px;
			}
		}
	}
	.carousel:not(.carousel-slider) {
		position: absolute;
		bottom: 0;
		.thumb {
			max-height: 60px;
		}
	}
	.control-dots {
		display: none;
	}
}